<div class="">
  <!-- d-lg-inline d-none -->
  <div class="header d-md-block d-none">
    <p>{{translate.data.caravanDetail.Reservation}}</p>
  </div>

  <div class="header d-md-none d-block" data-bs-toggle="collapse" href="#collapseExample" role="button" aria-expanded="false" aria-controls="collapseExample">
    <p>{{translate.data.caravanDetail.Reservation}}</p>
  </div>
  <div class="body collapse d-md-inline-block" id="collapseExample">
    <div class="title">
      <p>{{karavan?.pricePerDay}}</p>
      <p> {{translate.data.caravanCard.coin}}/{{translate.data.caravanCard.night}}</p>
    </div>
    <div class="loc">
      <img src="../../../../assets/Content/location-pin/location-pin.png" />
      <input type="text" [(ngModel)]="selectedCountry"
            class="input" [value]="karavan ? karavan[this.translate.data.requestdData.carGenCountry] : 'N/A'"/>
  </div>
  <div class="loc">
    <img src="../../../../assets/Content/location-pin/location-pin.png" />
    <input type="text" [(ngModel)]="selectedCity" class="input" [value]="karavan ? karavan[this.translate.data.requestdData.carGenCity] : 'N/A' "/>
  </div>

    <div class="loc" (click)="onSelect(date1)">
      <img src="../../../../assets/Content/calendar/calendar.png" />
      <p-calendar [(ngModel)]="date1"
                  [placeholder]="translate.is_arabic ? 'تاريخ المغادره' : 'leave Date'"
                  [readonlyInput]="true"
                  dateFormat="dd.mm.yy"
                  [disabledDates]="invalidDate"
                  [minDate]="todayDate"  >
      </p-calendar>
    </div>
      <div class="loc" (click)="onSelect(date2)">
        <img src="../../../../assets/Content/calendar/calendar.png" />
        <p-calendar [(ngModel)]="date2"
                    [placeholder]="translate.is_arabic ? 'تاريخ العوده' : 'Back Date'"
                    [readonlyInput]="true"
                    dateFormat="dd.mm.yy"
                    [disabledDates]="invalidDate"
                    [minDate]="minDateValue">
        </p-calendar>
      </div>

    <div class="titles-cont">
      <p>{{translate.data.caravanDetail.addedTax}}</p>
      <div class="title-cont">
        <p class="title-p1">{{taxValue}}</p>
        <p>{{translate.data.caravanCard.coin}}</p>
      </div>
    </div>

    <div class="titles-cont">
      <p>{{translate.data.caravanCard.total}}</p>
      <div class="title-cont">
        <p class="title-p1">{{total}}</p>
        <p>{{translate.data.caravanCard.coin}}</p>
      </div>
    </div>

    <button (click)="onhandle()" [disabled]="disabledBoolean">{{translate.data.caravanDetail.Enquiry}}</button>

    <div class="end-title"><p>{{translate.data.caravanDetail.rent}}</p></div>

  </div>
</div>

<!-- <div class="d-lg-none d-inline mt-2">
  <div class="title-sm">
    <p>{{karavan?.pricePerDay}}</p>
    <p>  {{translate.data.caravanCard.coin}} / {{translate.data.caravanCard.night}}</p>
  </div>
  <div>
    <button class="btn-sm" (click)="onhandle()">{{translate.data.caravanDetail.Enquiry}}</button>
  </div>
</div> -->

<!-- -------------------------------------------------- -->

<p-dialog  [(visible)]="display" contentStyleClass="mydialog" [closable]="false">
  <ng-template pTemplate="header" styleClass="header-img">
    <div class="header-img">
      <img (click)="hideDialog()" src="../../../assets/profile/modalformicons/753345.svg" alt="">
    </div>
</ng-template>
  <div class="body-modal">
    <img src="../../../../assets//caravan/Group 67974.png" alt="img">
    <h5>{{translate.data.caravanDetail.regesterFirst}}</h5>
    <p>{{translate.data.caravanDetail.toCanShare}}</p>
  </div>

  <p-footer>
    <div class="footer-modal">
      <button class="main-button" (click)="signIn()">{{translate.is_arabic ? 'تسجيل الأن' : 'Register'}}</button>
      <button class="sub-button" (click)="hideDialog()" >{{translate.is_arabic ? 'إلغاء' : 'Cancel'}}</button>
    </div>
</p-footer>
</p-dialog>


