<div class="container">
  <div class="caravan" [ngClass]="{'directionContent': translate.is_arabic, 'directionLeft': !translate.is_arabic}">
    <div class="row">
      <div class="col-lg-9 col-12">
         <div [ngClass]="{'header-card': translate.is_arabic, 'header-cardEn': !translate.is_arabic}">
           <p>{{translate.data.caravanDetail.caravanDetail}}</p>
         </div>
         <div class="body-card">
            <div class="caravan-card">
              <div class="img-container">
                <div class="btns-div">
                    <button (click)="handelHeart()" class="btttn heartBtn" *ngIf="!data?.isFavorite">
                    </button>
                    <button (click)="handelHeart()" class="btttn disheartBtn" *ngIf="data?.isFavorite">
                    </button>
                    <button class="btttn shareBtn" (click)="copyLink()" ngxClipboard [cbContent]="navUrl">
                    </button>
                </div>
                <img src="{{sharedService.ipRootImg + singleImage}}" alt="">
              <div class="img-sm-container">
                <img *ngFor="let img of data?.images"
                      src="{{sharedService.ipRootImg + img?.imagePath}}"
                      [ngClass]="{'activeImg': img == singleImage}"
                      alt="" title=""
                      (click)="handelClickImg(img?.imagePath)"/>
              </div>
            </div>
              </div>

            <div [ngClass]="{'caravan-details': translate.is_arabic, 'caravan-detailsEn': !translate.is_arabic}">
              <div class="title-container">
                <img class="twenty" src="../../../assets/caravan/Group 10466.svg" alt="">
                <star-rating *ngIf="data?.rate" [value]="data?.rate"
                    [totalstars]="5"
                    checkedcolor="#ed6c37"
                    uncheckedcolor="rgb(224, 224, 224)"
                    size="24px"
                    [readonly]="true">
              </star-rating>
              </div>
              <p class="caravan-name">{{data ? data[translate.data.requestdData.genName] : 'N/A'}}</p>
              <p class="caravan-desp">{{translate.data.caravanDetail.desc}}</p>
              <p class="caravan-description">{{data ? data[translate.data.requestdData.genDescription] : 'N/A'}}</p>
              <div class="more-about">
                <!-- <p>عرض المزيد</p> -->
                <img src="../../../assets/caravan/Path 3231.svg" alt="">
              </div>
              <p class="prev">{{translate.data.result.features}}</p>
              <div class="box-container">
                <div>
                  <p>{{translate.data.caravanDetail.seat}} {{data?.seatNo}}</p>
                  <img src="../../../assets/caravan/office-chair.svg" alt="">
                </div>
                <div>
                  <p>{{translate.data.caravanDetail.seat}} {{data?.bedsNo}}</p>
                  <img src="../../../assets/caravan/bed.svg" alt="">
                </div>
              </div>

            </div>
         </div>

         <div class="col-12 d-lg-none d-inline">
          <app-karavan-detail-side-menu></app-karavan-detail-side-menu>
        </div>

         <div class="menu-body">
           <div class="header">
              <ul>
                <li [class]="nav1" (click)=onActive1() >{{translate.data.result.features}}</li>
                <li [class]="nav2" (click)=onActive2() >{{translate.data.caravanDetail.roles}}</li>
                <li [class]="nav4" (click)=onActive4() >{{translate.data.caravanDetail.map}}</li>
                <li [class]="nav5" (click)=onActive5() *ngIf="authSer.isAuth()">{{translate.data.caravanDetail.clientOpinion}}</li>
              </ul>
           </div>
           <div class="body">
             <div [class]="nav1Active">
               <div *ngFor="let d of data?.features; index as i;">
                <div class="labels">
                  <img src="../../../assets/caravan/toilet.svg" alt="">
                  <p>{{d ? d[translate.data.requestdData.genName] : 'N/A'}}</p>
                </div>
                <div class="rows" >
                  <div class="element" *ngFor="let o of d?.featureItems; index as i;">
                    <img src="../../../assets/caravan/checked.svg" alt="">
                    <p>{{o ? o[translate.data.requestdData.genName] : 'N/A'}}</p>
                  </div>
                </div>
              </div>
            </div>

            <div [class]="nav2Active">
              <div *ngFor="let r of data?.roles">
                <div class="labels">
                  <img src={{sharedService.ipRootImg+r?.photo}} alt="">
                  <p>{{r ? r[translate.data.requestdData.genName] : 'N/A'}}</p>
                </div>
                <div class="rows">
                  <div class="element" *ngFor="let d of r?.roleItems">
                    <img src="../../../assets/caravan/checked.svg" alt="">
                    <p>{{d ? d[translate.data.requestdData.genName] : 'N/A'}}</p>
                  </div>
                </div>
              </div>
            </div>

            <div [class] = "nav4Active">
              <div class="map">
                <div class="map-title-cont">
                  <img src="../../../../assets/Content/location-pin/location-pin.png" />
                  <div>
                    <p class="map-title-p1">{{translate.data.caravanDetail.region}}</p>
                    <p class="map-title-p2">{{data?.address}}</p>
                  </div>
                </div>
                <div class="map-cont">
                  <!-- <img class="map-img" src="../../../assets/caravan/Mask Group 2/Mask Group 2.png" alt="">
                  <img class="loc-img" src="../../../../assets/Content/location-pin/location-pin.png" />
                  <p>Show on map</p> -->
                  <agm-map [latitude]="lat" [longitude]="lng">
                    <agm-marker [latitude]="lat" [longitude]="lng"></agm-marker>
                  </agm-map>
                </div>
              </div>
            </div>

            <div [class]="nav5Active">
              <div class="review">
                <div *ngFor="let d of reveiwData">
                <div class="header-5">
                  <div class="header-5-cont">
                    <div>
                      <p class="fw-bold">{{d?.userName}}</p>
                    </div>
                </div>
                  <star-rating [value]="d?.rate"
                  [totalstars]="5"
                  checkedcolor="#ed6c37"
                  uncheckedcolor="rgb(224, 224, 224)"
                  size="24px"
                  [readonly]="true">
                </star-rating>
                </div>
                <div class="body-5">
                  <p>{{d?.review}}</p>
                  <div class="more-about">
                    <img src="../../../assets/caravan/Path 3231.svg" alt="">
                  </div>
                </div>
                </div>

                <div [ngClass]="{'review-form': translate.is_arabic, 'review-formEn': !translate.is_arabic}" *ngIf="authSer.isAuth()">
                  <p class="review-form-title">{{translate.data.caravanDetail.addComment}}</p>
                  <p class="review-form-sub-title"></p>

                  <form class="form g-3 d-flex flex-wrap needs-validation">
                    <div class="form-floating col-12">
                      <textarea class="form-control mb-4 input"
                                placeholder="{{translate.is_arabic ? 'التعليق' : 'comment'}}"
                                id="floatingTextarea2"
                                [(ngModel)]="formData.review"
                                [ngModelOptions]="{standalone: true}"
                                required
                                style="height: 100px"
                      ></textarea>
                      <label class="edit" for="floatingTextarea2">{{translate.is_arabic ? 'التعليق' : 'comment'}}</label>
                    </div>
                    <div style="width: 100%;">
                      <p>{{translate.is_arabic ? 'التقييم' : 'Rate'}}</p>
                      <star-rating [value]="rateNumber"
                                  [totalstars]="5"
                                  checkedcolor="#ed6c37"
                                  uncheckedcolor="rgb(224, 224, 224)"
                                  size="24px"
                                  [readonly]="false" (rate)="onRate($event)">
                      </star-rating>
                    </div>
                    <button type="submit" (click)="handelFormSubmitted()" [disabled]="disabledSubmitted">
                      ارسال
                    </button>
                  </form>
                </div>

              </div>

            </div>


           </div>
         </div>
      </div>
      <div class="col-3 d-lg-inline d-none">
        <app-karavan-detail-side-menu></app-karavan-detail-side-menu>
      </div>
    </div>
  </div>
</div>
<router-outlet></router-outlet>
