import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {CaravanDetailsComponent} from './caravan-details.component'

const routes: Routes = [
  {path: '', component: CaravanDetailsComponent},
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CaravanDetailsRoutingModule { }
