import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Params, Router } from '@angular/router';
import { TranslationService } from 'src/app/shared/translation.service';
import { PagesService} from '../../pages/pages.service'
import { SharedService } from 'src/app/shared/shared.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthenticationService } from '../../authentication/Services/authentication.service';
// import { AuthService } from 'src/app/authentication/Services/auth.service';
//import { google } from '@google/maps';


@Component({
  selector: 'app-caravan-details',
  templateUrl: './caravan-details.component.html',
  styleUrls: ['./caravan-details.component.scss']
})
export class CaravanDetailsComponent implements OnInit {




  navUrl: string;
  // map
  latLong = {};


  rateNumber: number;

  nav1:string ="active"
  nav2:string ="";
  nav3:string ="";
  nav4:string ="";
  nav5:string ="";

  nav1Active:string = "d-inline"
  nav2Active:string = "d-none"
  nav3Active:string = "d-none"
  nav4Active:string = "d-none"
  nav5Active:string = "d-none"

  id:any; // karavan id

  data:any; // karaban data

  //reservate dates
  reservateDates :any;

  // reveiw data
  reveiwData:any [] = [];

  //Rules Data
  rulesData:any;
  disabledSubmitted: boolean = false;

//imgaes viewer data
singleImage: string;
imagesList: string[];
lat: any = 24.774265;
long: any = 46.738586;

  //form data opinion client
  formData = {
    rate: 0,
    review: '',
    carvanId: null,
  };

  // lat = 13;
  lng = 80;

  constructor(private router: Router,
    public translate: TranslationService,
    private route: ActivatedRoute,
    private pagesService: PagesService,
    public authSer: AuthenticationService,
    private spinner: NgxSpinnerService,
    public sharedService: SharedService ) { }

  ngOnInit() {

    this.spinner.show();
    //catch karaavn id
    this.route.params.subscribe(
      (params: Params) => {
        this.id = params['id'];
        this.formData.carvanId = this.id;
      }
    )

    //get karavan data by ID
    this.pagesService.getCarvanById(this.id).subscribe(
       (response: any) => {
        this.data =  response.data;
        this.singleImage = this.data?.images[0]?.imagePath;
        console.log("karavan data ===> ", this.data);
        this.latLong = {
          lat: this.data.lat,
          long: this.data.long
        };

        console.log("lat & lang", this.latLong);

         this.spinner.hide();

      },
      (error) => {
        console.log(error);
        this.spinner.hide();
      }
    );


    //get karavan detail by ID
/*     this.pagesService.getReviewByCarvanId(this.id).subscribe(
      (response: any) => {
        this.reveiwData = response.data;
      },
      (error:any) => {
        console.log(error);
      }
    ) */

    //Rules
 /*    this.pagesService.getCarvanRulesById(this.id).subscribe(
      (response: any) => {
        this.rulesData = response.data;

      },
      (error:any) => {
        console.log(error);
      }
    ) */

    //Reservations
/*     this.pagesService.getReservedDatesById(this.id).subscribe(
      (response: any) => {
        this.reservateDates = response.data;
        console.log("reservateDates",this.reservateDates);
      },
      (error:any) => {
        console.log(error);
      }
    ); */

  }

  onActive1=()=>{
    this.nav1 = "active";
    this.nav2 = "";
    this.nav3 = "";
    this.nav4 = "";
    this.nav5 = "";
    this.nav1Active = "d-inline"
    this.nav2Active = "d-none"
    this.nav3Active = "d-none"
    this.nav4Active = "d-none"
    this.nav5Active = "d-none"
  }

  onActive2=()=>{
    this.nav1 = "";
    this.nav2 = "active";
    this.nav3 = "";
    this.nav4 = "";
    this.nav5 = "";
    this.nav1Active = "d-none"
    this.nav2Active = "d-inline"
    this.nav3Active = "d-none"
    this.nav4Active = "d-none"
    this.nav5Active = "d-none"
  }

  onActive3=()=>{
    this.nav1 = "";
    this.nav2 = "";
    this.nav3 = "active";
    this.nav4 = "";
    this.nav5 = "";
    this.nav1Active = "d-none"
    this.nav2Active = "d-none"
    this.nav3Active = "d-inline"
    this.nav4Active = "d-none"
    this.nav5Active = "d-none"
  }

  onActive4=()=>{
    this.nav1 = "";
    this.nav2 = "";
    this.nav3 = "";
    this.nav4 = "active";
    this.nav5 = "";
    this.nav1Active = "d-none"
    this.nav2Active = "d-none"
    this.nav3Active = "d-none"
    this.nav4Active = "d-inline"
    this.nav5Active = "d-none"
  }

  onActive5=()=>{
    this.nav1 = "";
    this.nav2 = "";
    this.nav3 = "";
    this.nav4 = "";
    this.nav5 = "active";
    this.nav1Active = "d-none"
    this.nav2Active = "d-none"
    this.nav3Active = "d-none"
    this.nav4Active = "d-none"
    this.nav5Active = "d-inline"
  }

  onRate(e: any) {
    this.rateNumber = e.newValue;
    this.formData.rate = this.rateNumber;
  }


  handelClickImg(img: string) {
    this.singleImage = img;
  }

  //on copy link url
  copyLink() {
    this.navUrl = window.location.href;
    this.sharedService.stringErrorMessage.next('تم نسخ الرابط');
    this.sharedService.errorShowMessage.next(true);
    this.sharedService.hideErrorMessage();
  }


  //handel heart click
  handelHeart() {
    const data = {
      id: this.data.id,
      isFavorite: !this.data.isFavorite
    };

    this.pagesService.setFavourite(data).subscribe(
      (response: any) => {
        console.log(response);
        this.data.isFavorite = !this.data.isFavorite;
      },
      (error) => {
        console.log(error);
        if(error.status == 401) {
          this.data.isFavorite = this.data.isFavorite;
          this.sharedService.stringErrorMessage.next('قم بتسجيل الدخول اولاً');
          this.sharedService.errorShowMessage.next(true);
          this.sharedService.hideErrorMessage();
        }
      }
    );

  }

  handelFormSubmitted(){
    console.log("belasy");
    this.disabledSubmitted = true;
    const user = this.authSer.getUser();
    const subData = {...this.formData, userName: user.fullName};
    this.pagesService.addNewReview(subData).subscribe(
      (response: any) => {
        this.reveiwData.push(subData);
        this.formData.review = '';
        this.rateNumber = 0;
        this.disabledSubmitted = false;
     },
     (error:any) => {
      this.disabledSubmitted = false;
      this.sharedService.stringErrorMessage.next(error.error.error);
      this.sharedService.errorShowMessage.next(true);
      this.sharedService.hideErrorMessage();
       console.log(error);
     }
   );



  }

}
