import { Component, OnInit, Input } from '@angular/core';

import { ActivatedRoute, Params, Router } from '@angular/router';
import { TranslationService } from 'src/app/shared/translation.service';
import { PagesService } from '../../pages.service';
import { AuthenticationService } from '../../../authentication/Services/authentication.service';
import { DatePipe } from '@angular/common';
import { SharedService } from 'src/app/shared/shared.service';

@Component({
  selector: 'app-karavan-detail-side-menu',
  templateUrl: './karavan-detail-side-menu.component.html',
  styleUrls: ['./karavan-detail-side-menu.component.scss'],
})
export class KaravanDetailSideMenuComponent implements OnInit {
  //today date
  todayDate = new Date();
  selectedCity: string;
  selectedCountry: string;
  sendData: any = {};
  res: any;
  date1: any;
  date2: any;
  total: number;
  display: any;
  city: any;
  country: any;
  noDays: any;
  minDateValue: any;

  date11: any;
  date12: any;

  dt: any;

  disabledBoolean: boolean = false;

  invalidDate: Date[] = [];

  karavan: any;
  tax: any;
  taxValue:any;

  showDialog() {
    this.display = true;
  }

  hideDialog() {
    this.display = false;
  }

  constructor(
    private router: Router,
    public translate: TranslationService,
    private route: ActivatedRoute,
    private pagesService: PagesService,
    public authService: AuthenticationService,
    private sharedService: SharedService,
    public datepipe: DatePipe
  ) {}

  ngOnInit() {
    //catch route from URL
    this.route.params.subscribe((params: Params) => {
      this.pagesService.getCarvanById(params['id']).subscribe(
        (response: any) => {
          this.karavan = response.data;
          console.log('karavan ==> ', this.karavan);
          this.selectedCountry =
            this.karavan[this.translate.data.requestdData.carGenCountry];
          this.selectedCity =
            this.karavan[this.translate.data.requestdData.carGenCity];
        },
        (error) => {
          console.log(error);
        }
      );

      //Reservations
      this.pagesService.getReservedDatesById(params['id']).subscribe(
        (response: any) => {
          console.log('reservations daaaaateeeee ===> ', response);
          if (response.data) {
            console.log('reservationnnsss dataaa => ', response.data);
            response.data.forEach((element: any) => {
              console.log('==> ', element);
              const invalidfromDate = element?.fromDate.split(' ')[0];
              const invalidToDate = element?.toDate.split(' ')[0];
              this.dateRange(invalidfromDate, invalidToDate);
            });
            /*  const invalidToDate = response?.data[0]?.toDate.split(' ')[0];
            const invalidfromDate = response?.data[0]?.fromDate.split(' ')[0];
            this.dateRange(invalidfromDate, invalidToDate); */
          }
        },
        (error: any) => {
          console.log(error);
        }
      );
    });

    this.pagesService.getTaxById(1).subscribe(
      (response: any) => {
        this.tax = response.data.tax;
      },
      (error) => {
        console.log(error);
      }
    );
    this.total = 0;
    this.taxValue = 0;
  }

  onSelect = (d: any) => {
    console.log('entered');
    if (this.date1) {
      this.minDateValue = this.date1;
    }
    this.getTotal();
  };

  //handel book karavan
  onhandle() {
    if (this.authService.isAuth()) {
      this.onsumbit();
    } else {
      this.showDialog();
    }
  }

  getNoOfDays = () => {
    let oneDay = 24 * 60 * 60 * 1000;
    let noDays = Math.round(Math.abs((this.date1 - this.date2) / oneDay))+parseFloat("1") ;
   // console.log(oneDay);
   // console.log(Math.abs((this.date1 - this.date2)));
   // console.log(this.date2);
   // console.log(this.date1);


    return noDays;
  };

  getTotal = () => {
    let noDay: any = this.getNoOfDays();


    if (noDay) {
      this.taxValue = this.karavan.pricePerDay * noDay * (this.tax / 100);
      this.total = this.karavan.pricePerDay * noDay + this.taxValue;

      console.log(this.tax);
      console.log(this.karavan.pricePerDay);
      console.log(noDay);

    } else {
      this.total = 0;
    }
  };

  //redirect to login page
  signIn = () => {
    this.router.navigate(['auth']);
  };

  //get range of date
  dateRange(startDate: any, endDate: any, steps = 1) {
    const dateArray = [];
    let currentDate = new Date(startDate);
    while (currentDate <= new Date(endDate)) {
      this.invalidDate.push(new Date(currentDate));
      dateArray.push(new Date(currentDate));
      // Use UTC date to prevent problems with time zones and DST
      currentDate.setUTCDate(currentDate.getUTCDate() + steps);
    }
    console.log(dateArray);
    return dateArray;
  }

  onsumbit = async () => {
    //to change formate date
    let date1 = this.datepipe.transform(this.date1, 'yyyy-MM-dd');
    let date2 = this.datepipe.transform(this.date2, 'yyyy-MM-dd');

    let noDay: any = this.getNoOfDays();

    this.sendData = {
      carvanId: this.karavan.id,
      fromDate: date1,
      toDate: date2,
      price: this.karavan.pricePerDay,
      taxValue: String(this.karavan.taxPerDay),
      dayCount: noDay,
    };

    console.log(this.sendData);

    if (!this.sendData.fromDate) {
      this.sharedService.stringErrorMessage.next('أختر تاريخ المغادره');
      this.sharedService.errorShowMessage.next(true);
      this.sharedService.hideErrorMessage();
      return;
    }

    if (!this.sendData.toDate) {
      this.sharedService.stringErrorMessage.next('أختر تاريخ العوده');
      this.sharedService.errorShowMessage.next(true);
      this.sharedService.hideErrorMessage();
      return;
    }

    this.disabledBoolean = true;
    this.pagesService.postReservation(this.sendData).subscribe(
      (response: any) => {
        this.res = response.data;
        console.log('caravan', this.res);
        this.disabledBoolean = false;
        this.router.navigate(['../../reserve/' + this.res], {
          relativeTo: this.route,
        });
      },
      (error) => {
        console.log(error);
        this.disabledBoolean = false;
      }
    );
  };
}
