import { NgModule , CUSTOM_ELEMENTS_SCHEMA} from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { CaravanDetailsRoutingModule } from './caravan-details-routing.module';
import { CaravanDetailsComponent } from './caravan-details.component';
import { KaravanDetailSideMenuComponent } from './karavan-detail-side-menu/karavan-detail-side-menu.component';
import {DropdownModule} from 'primeng/dropdown';
import {TabViewModule} from 'primeng/tabview';
import {CalendarModule} from 'primeng/calendar';
import {DialogModule} from 'primeng/dialog';

import { RatingModule } from 'ng-starrating';

//import { AgmCoreModule } from '@agm/core';
import { PagesSharedModule } from '../pagesShared.module';



import { AgmCoreModule } from '@agm/core';


@NgModule({
  declarations: [CaravanDetailsComponent, KaravanDetailSideMenuComponent],
  imports: [
    DialogModule,
    CommonModule,
    CaravanDetailsRoutingModule,
    DropdownModule,
    TabViewModule,
    FormsModule,
    CalendarModule,
    RouterModule,
    RatingModule,
    PagesSharedModule,
   /*  AgmCoreModule.forRoot({
      apiKey: 'AIzaSyByQ4RUtcrnc4zWeq4XgQ7VuaSgFIXSUuk'
    }) */
    DialogModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyCF7suVZseF7t8G-TOD76uk-bHBSIM7xBU'
    }),
    //AngularCalendarYearViewModule,
  ],
  schemas:[CUSTOM_ELEMENTS_SCHEMA],
  providers:[
    DatePipe
  ]
})
export class CaravanDetailsModule { }
